import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';

import * as DiscountReportActions from './discount-report.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { of } from 'rxjs';
import { DiscountReportService } from 'src/app/services/Reports/Discount/discount-report.service';
import { IReport } from 'src/app/models/Report/Components/IReport';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';

@Injectable()
export class DiscountReportEffects {
  constructor(private action$: Actions, private discountReportService: DiscountReportService) { }

  loadReport$ = createEffect(
    () => this.action$.pipe(
      ofType(DiscountReportActions.DiscountReportActionTypes.LoadDiscountReport),
      mergeMap((action: DiscountReportActions.LoadDiscountReport) => {

          // store the parameters into the localstorage
        action.payload.storeParameters();

        return this.discountReportService.getDiscountReport(action.payload).pipe(
          map((report: IReport) => (new DiscountReportActions.LoadDiscountReportSuccessful(report))),
          catchError((error: HttpErrorResponse) => of(new DiscountReportActions.LoadDiscountReportFailed(interpretateApiError(error)))
        ));
      })
    )
  );

  downloadDiscountReportFile$ = createEffect(
    () => this.action$.pipe(
      ofType(DiscountReportActions.DiscountReportActionTypes.DownloadPDF),
      mergeMap((action: DiscountReportActions.DownloadPDF) => {

        return this.discountReportService.getReportFilePDF(action.definition, action.pdfDefinition, action.sortDefinitions).pipe(
          map((file: ArrayBuffer) => (new DiscountReportActions.DownloadPDFSuccessful(file))),
          catchError((error: HttpErrorResponse) => of(new DiscountReportActions.DownloadPDFFailed(interpretateApiError(error)))
        ));
      })
    )
  );

  downloadVoidReportFile2$ = createEffect(
    () => this.action$.pipe(
      ofType(DiscountReportActions.DiscountReportActionTypes.DownloadXLS),
      mergeMap((action: DiscountReportActions.DownloadXLS) => {

        return this.discountReportService.getReportFileXLS(action.definition, action.xlsDefinition, action.sortDefinition).pipe(
          map((file: ArrayBuffer) => (new DiscountReportActions.DownloadXLSSuccessful(file))),
          catchError((error: HttpErrorResponse) => of(new DiscountReportActions.DownloadXLSFailed(interpretateApiError(error)))
        ));
      })
    )
  );
}
