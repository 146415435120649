import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';

import * as ProductSalesReportActions from './product-sales-report.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { of } from 'rxjs';
import { ProductSalesReportService } from 'src/app/services/Reports/ProductSales/product-sales-report.service';
import { IReport } from 'src/app/models/Report/Components/IReport';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';

@Injectable()
export class ProductSalesReportEffects {
  constructor(private action$: Actions, private productSalesReportService: ProductSalesReportService) { }

  loadReport$ = createEffect(
    () => this.action$.pipe(
      ofType(ProductSalesReportActions.ProductSalesReportActionTypes.LoadProductSalesReport),
      mergeMap((action: ProductSalesReportActions.LoadProductSalesReport) => {

        // store the parameters into the localstorage
        action.payload.storeParameters();

        return this.productSalesReportService.getProductSalesReport(action.payload).pipe(
          map((report: IReport) => (new ProductSalesReportActions.LoadProductSalesReportSuccessful(report))),
          catchError((error: HttpErrorResponse) => of(new ProductSalesReportActions.LoadProductSalesReportFailed(interpretateApiError(error)))
        ));
      })
    )
  );

  downloadReportPDF$ = createEffect(
    () => this.action$.pipe(
      ofType(ProductSalesReportActions.ProductSalesReportActionTypes.DownloadPDF),
      mergeMap((action: ProductSalesReportActions.DownloadPDF) => {

        return this.productSalesReportService.getReportFilePDF(action.definition, action.pdfDefinition, action.sortDefinition).pipe(
          map((file: ArrayBuffer) => (new ProductSalesReportActions.DownloadPDFSuccessful(file))),
          catchError((error: HttpErrorResponse) => of(new ProductSalesReportActions.DownloadPDFFailed(interpretateApiError(error)))
        ));
      })
    )
  );

  downloadReportXLS$ = createEffect(
    () => this.action$.pipe(
      ofType(ProductSalesReportActions.ProductSalesReportActionTypes.DownloadXLS),
      mergeMap((action: ProductSalesReportActions.DownloadXLS) => {

        return this.productSalesReportService.getReportFileXLS(action.definition, action.xlsDefinition, action.sortDefinition).pipe(
          map((file: ArrayBuffer) => (new ProductSalesReportActions.DownloadXLSSuccessful(file))),
          catchError((error: HttpErrorResponse) => of(new ProductSalesReportActions.DownloadXLSFailed(interpretateApiError(error)))
        ));
      })
    )
  );
}
