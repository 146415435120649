export enum ConsolidatedProductSalesReportField
{
  None,
  DisplayName,
  Category,
  ParentCategory,
  Course,
  Quantity,
  LineItemTotalPrice
}

export const ConsolidatedProductSalesReportFieldLabel = new Map<number, string>([
  [ConsolidatedProductSalesReportField.None, 'None'],
  [ConsolidatedProductSalesReportField.DisplayName, 'Product'],
  [ConsolidatedProductSalesReportField.Category, 'Category'],
  [ConsolidatedProductSalesReportField.ParentCategory, 'Parent Category'],
  [ConsolidatedProductSalesReportField.Course, 'Course'],
  [ConsolidatedProductSalesReportField.Quantity, 'Quantity'],
  [ConsolidatedProductSalesReportField.LineItemTotalPrice, 'Gross']
]);
