import { Action } from '@ngrx/store';
import { IReport } from 'src/app/models/Report/Components/IReport';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { PaymentReportParameters } from 'src/app/models/Report/ReportParameters/IPaymentReportParameters';
import { IReportDefinition } from 'src/app/models/Report/Components/IReportDefinition';
import { IReportPDFDefinition } from 'src/app/models/Report/Components/IReportPDFDefinition';
import { IReportSortDefinition } from 'src/app/models/Report/Components/IReportSortDefinition';
import { IReportXLSDefinition } from 'src/app/models/Report/Components/IReportXLSDefinition';

export enum PaymentReportActionTypes {
    LoadPaymentReport = '[Payment Report] Load Payment Report',
    LoadPaymentReportSuccessful = '[Payment Report] Load Payment Report - Successful',
    LoadPaymentReportFailed = '[Payment Report] Load Payment Report - Failed',
    ResetStore = '[Payment Report] Reset Store',
    SaveSearchParameters = '[Payment Report] Save Search Parameters',
    DownloadPDF = '[Payment Reports] Download Payment Reports PDF',
    DownloadPDFSuccessful = '[Payment Reports] Download Payment Reports PDF - Successful',
    DownloadPDFFailed = '[Payment Reports] Download Payment Reports PDF - Failed',
    DownloadXLS = '[Payment Reports] Download Payment Reports XLS',
    DownloadXLSSuccessful = '[Payment Reports] Download Payment Reports XLS - Successful',
    DownloadXLSFailed = '[Payment Reports] Download Payment Reports XLS - Failed',
    CleanUpFileBuffer = '[Payment Reports] Clean up file buffer'
}

export class LoadPaymentReport implements Action {
    readonly type = PaymentReportActionTypes.LoadPaymentReport;

    constructor(public payload: PaymentReportParameters) { }
}

export class LoadPaymentReportSuccessful implements Action {
    readonly type = PaymentReportActionTypes.LoadPaymentReportSuccessful;

    constructor(public payload: IReport) { }
}

export class LoadPaymentReportFailed implements Action {
    readonly type = PaymentReportActionTypes.LoadPaymentReportFailed;

    constructor(public payload: IGenericError) { }
}

export class ResetStore implements Action {
    readonly type = PaymentReportActionTypes.ResetStore;

    constructor() { }
}

export class SaveSearchParameters implements Action {
    readonly type = PaymentReportActionTypes.SaveSearchParameters;

    constructor(public payload: PaymentReportParameters) { }
}

export class DownloadPDF implements Action {
  readonly type = PaymentReportActionTypes.DownloadPDF;

  constructor(public definition: IReportDefinition, public pdfDefinition: IReportPDFDefinition, public sortDefinition: IReportSortDefinition[]) { }
}

export class DownloadPDFSuccessful implements Action {
  readonly type = PaymentReportActionTypes.DownloadPDFSuccessful;

  constructor(public payload: ArrayBuffer) { }
}

export class DownloadPDFFailed implements Action {
  readonly type = PaymentReportActionTypes.DownloadPDFFailed;

  constructor(public payload: IGenericError) { }
}

export class DownloadXLS implements Action {
  readonly type = PaymentReportActionTypes.DownloadXLS;

  constructor(public definition: IReportDefinition, public xlsDefinition: IReportXLSDefinition, public sortDefinition: IReportSortDefinition[]) { }
}

export class DownloadXLSSuccessful implements Action {
  readonly type = PaymentReportActionTypes.DownloadXLSSuccessful;

  constructor(public payload: ArrayBuffer) { }
}

export class DownloadXLSFailed implements Action {
  readonly type = PaymentReportActionTypes.DownloadXLSFailed;

  constructor(public payload: IGenericError) { }
}

export class CleanUpFileBuffer implements Action {
  readonly type = PaymentReportActionTypes.CleanUpFileBuffer;

  constructor() { }
}

export type PaymentReportActions = LoadPaymentReport
    | LoadPaymentReportSuccessful
    | LoadPaymentReportFailed
    | ResetStore
    | SaveSearchParameters
    | DownloadPDF
    | DownloadPDFSuccessful
    | DownloadPDFFailed
    | DownloadXLS
    | DownloadXLSSuccessful
    | DownloadXLSFailed
    | CleanUpFileBuffer;
