import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';

import * as PaymentReportActions from './payment-report.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { of } from 'rxjs';
import { PaymentReportService } from 'src/app/services/Reports/Payment/payment-report.service';
import { IReport } from 'src/app/models/Report/Components/IReport';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';

@Injectable()
export class PaymentReportEffects {
  constructor(private action$: Actions, private paymentReportService: PaymentReportService) { }

  loadReport$ = createEffect(
    () => this.action$.pipe(
      ofType(PaymentReportActions.PaymentReportActionTypes.LoadPaymentReport),
      mergeMap((action: PaymentReportActions.LoadPaymentReport) => {

        // store the parameters into the localstorage
        action.payload.storeParameters();

        return this.paymentReportService.getPaymentReport(action.payload).pipe(
          map((report: IReport) => (new PaymentReportActions.LoadPaymentReportSuccessful(report))),
          catchError((error: HttpErrorResponse) => of(new PaymentReportActions.LoadPaymentReportFailed(interpretateApiError(error))))
        );
      })
    )
  );

  downloadPDF$ = createEffect(
    () => this.action$.pipe(
      ofType(PaymentReportActions.PaymentReportActionTypes.DownloadPDF),
      mergeMap((action: PaymentReportActions.DownloadPDF) => {
        return this.paymentReportService.getReportFilePDF(action.definition, action.pdfDefinition, action.sortDefinition).pipe(
          map((file: ArrayBuffer) => (new PaymentReportActions.DownloadPDFSuccessful(file))),
          catchError((error: HttpErrorResponse) => of(new PaymentReportActions.DownloadPDFFailed(interpretateApiError(error))))
        );
      })
    )
  );

  downloadXLS$ = createEffect(
    () => this.action$.pipe(
      ofType(PaymentReportActions.PaymentReportActionTypes.DownloadXLS),
      mergeMap((action: PaymentReportActions.DownloadXLS) => {
        return this.paymentReportService.getReportFileXLS(action.definition, action.xlsDefinition, action.sortDefinition).pipe(
          map((file: ArrayBuffer) => (new PaymentReportActions.DownloadXLSSuccessful(file))),
          catchError((error: HttpErrorResponse) => of(new PaymentReportActions.DownloadXLSFailed(interpretateApiError(error))))
        );
      })
    )
  );
}
