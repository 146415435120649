export enum ExpandedConsolidatedProductSalesReportField
{
  None,
  DisplayName,
  TaxBand,
  Category,
  ParentCategory,
  Course,
  Quantity,
  Net,
  Tax,
  LineItemTotalPrice
}

export const ExpandedConsolidatedProductSalesReportFieldLabel = new Map<number, string>([
  [ExpandedConsolidatedProductSalesReportField.None, 'None'],
  [ExpandedConsolidatedProductSalesReportField.DisplayName, 'Product'],
  [ExpandedConsolidatedProductSalesReportField.TaxBand, 'TaxBand'],
  [ExpandedConsolidatedProductSalesReportField.Category, 'Category'],
  [ExpandedConsolidatedProductSalesReportField.ParentCategory, 'Parent Category'],
  [ExpandedConsolidatedProductSalesReportField.Course, 'Course'],
  [ExpandedConsolidatedProductSalesReportField.Quantity, 'Quantity'],
  [ExpandedConsolidatedProductSalesReportField.Net, 'Net'],
  [ExpandedConsolidatedProductSalesReportField.Tax, 'Tax'],
  [ExpandedConsolidatedProductSalesReportField.LineItemTotalPrice, 'Gross']
]);
