import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IReport, Report } from '../../../models/Report/Components/IReport';
import { EstateService } from '../../estate.service';
import { LoginService } from '../../login.service';
import { IReportDefinition, ReportDefinition } from '../../../models/Report/Components/IReportDefinition';
import { OperatorType } from '../../../enums/Report/Components/OperatorType';
import { ProductSalesReportParameters } from '../../../models/Report/ReportParameters/IProductSalesReportParameters';
import { IReportPDFDefinition, ReportPDFDefinition } from '../../../models/Report/Components/IReportPDFDefinition';
import { ReportPDFEnvelope } from '../../../models/Report/Components/IReportPDFEnvelope';
import { IReportSortDefinition } from '../../../models/Report/Components/IReportSortDefinition';
import { IReportSectionSortDefinition } from 'src/app/models/Report/Components/IReportSectionSortDefinition';
import { ProductSalesReportField } from '../../../enums/Report/ReportField/ProductSales/ProductSalesReportField';
import { ExpandedProductSalesReportField } from 'src/app/enums/Report/ReportField/ProductSales/ExpandedProductSalesReportField';
import { ConsolidatedProductSalesReportField } from 'src/app/enums/Report/ReportField/ProductSales/ConsolidatedProductSalesReportField';
import { ProductSalesReportVariant } from 'src/app/enums/Report/ReportField/ProductSales/ProductSalesReportVariant';
import { ExpandedConsolidatedProductSalesReportField } from 'src/app/enums/Report/ReportField/ProductSales/ExpandedConsolidatedProductSalesReportField';
import { IReportXLSDefinition } from 'src/app/models/Report/Components/IReportXLSDefinition';
import { ReportXLSEnvelope } from 'src/app/models/Report/Components/IReportXLSEnvelope';

@Injectable({
  providedIn: 'root'
})
export class ProductSalesReportService {

  private defaultSimpleSort: IReportSortDefinition = {
    fieldNumber: ProductSalesReportField.Date - 1,
    isAscending: false
  };

  private defaultExpandedSort: IReportSortDefinition = {
    fieldNumber: ExpandedProductSalesReportField.Date - 1,
    isAscending: false
  };

  private defaultConsolidatedSort: IReportSortDefinition = {
    fieldNumber: ConsolidatedProductSalesReportField.DisplayName - 1,
    isAscending: false
  };

  private defaultExpandedConsolidatedSort: IReportSortDefinition = {
    fieldNumber: ExpandedConsolidatedProductSalesReportField.DisplayName - 1,
    isAscending: false
  };

  private token: string;
  private ProductSalesReportEndpointTemplate = environment.APIEndpoint + 'frontend/corporation/{corporationId}/site/{siteId}/UpdatedProductSalesReport/';

  constructor(private http: HttpClient, private loginService: LoginService, private estateService: EstateService) { }

  getProductSalesReport(parameters: ProductSalesReportParameters): Observable<IReport> {
    this.token = this.loginService.AccessToken.authToken;

    let _ProductSalesReportURL = this.urlBuilder('');

    let definition: IReportDefinition = this.generateProductSalesReportDefinition(parameters);

    return this.http.post<IReport>(_ProductSalesReportURL, definition, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token) }).pipe(
      map((data: IReport) => {

        if (data == null) {
          data = new Report();
        }

        data.reportDefinition = definition
        data.reportPDFDefinition = new ReportPDFDefinition()

        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(() => err);
      })
    );
  }

  // Returns Report PDF
  getReportFilePDF(definition: IReportDefinition, pdfDefinition: IReportPDFDefinition, sortDefinition: IReportSortDefinition[]): Observable<ArrayBuffer> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const _ProductSalesReportPDFURL = this.urlBuilder('PDF');

      definition.sectionSorts = this.generateSectionSort(sortDefinition, definition.variant);

      let envelope: ReportPDFEnvelope = {
        reportDefinition: definition,
        reportPDFDefinition: pdfDefinition
      }

      return this.http.post(_ProductSalesReportPDFURL, envelope, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), responseType: 'arraybuffer'}).pipe(
        map((data: ArrayBuffer) => {

          return data;

        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  // Returns Product Sales Report XLS
  getReportFileXLS(definition: IReportDefinition, xlsDefinition: IReportXLSDefinition, sortDefinition: IReportSortDefinition[]): Observable<ArrayBuffer> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const _ProductSalesReportXLSURL = this.urlBuilder('XLS');

      definition.sectionSorts = this.generateSectionSort(sortDefinition, definition.variant);

      let envelope: ReportXLSEnvelope = {
        reportDefinition: definition,
        reportXLSDefinition: xlsDefinition
      }

      return this.http.post(_ProductSalesReportXLSURL, envelope, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), responseType: 'arraybuffer'}).pipe(
        map((data: ArrayBuffer) => {

          return data;

        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private generateProductSalesReportDefinition(parameter: ProductSalesReportParameters): IReportDefinition {
    let reportDefinition = new ReportDefinition();

    reportDefinition.groupedFieldEnumValue = [parameter.reportGroup as number, parameter.reportSubGroup as number]

    let salesDateFrom = this.getFullDate(parameter.salesDateFromDay, parameter.salesDateFromHour, parameter.salesDateFromMinute)

    reportDefinition.filters.push({
      fieldEnumValue: ProductSalesReportField.Date,
      operatorType: OperatorType.GreaterThanOrEqual,
      value: salesDateFrom
    });

    let salesDateTo = this.getFullDate(parameter.salesDateToDay, parameter.salesDateToHour, parameter.salesDateToMinute)

    reportDefinition.filters.push({
      fieldEnumValue: ProductSalesReportField.Date,
      operatorType: OperatorType.LessThan,
      value: salesDateTo
    });

    if (parameter.posId != -1){
      reportDefinition.filters.push({
        fieldEnumValue: ProductSalesReportField.Pos,
        operatorType: OperatorType.Equal,
        value: parameter.posId
      });
    }

    if (parameter.clerkId != -1){
      reportDefinition.filters.push({
        fieldEnumValue: ProductSalesReportField.User,
        operatorType: OperatorType.Equal,
        value: parameter.clerkId
      });
    }

    if (parameter.productId != ''){
      reportDefinition.filters.push({
        fieldEnumValue: ProductSalesReportField.DisplayName,
        operatorType: OperatorType.Equal,
        value: parameter.productId
      });
    }

    if (parameter.categoryId != '') {
      reportDefinition.filters.push({
        fieldEnumValue: ProductSalesReportField.Category,
        operatorType: OperatorType.Equal,
        value: parameter.categoryId
      });
    }

    if (parameter.parentCategoryId != '') {
      reportDefinition.filters.push({
        fieldEnumValue: ProductSalesReportField.ParentCategory,
        operatorType: OperatorType.Equal,
        value: parameter.parentCategoryId
      });
    }

    if (parameter.courseId != '') {
      reportDefinition.filters.push({
        fieldEnumValue: ProductSalesReportField.Course,
        operatorType: OperatorType.Equal,
        value: parameter.courseId
      });
    }

    //default sort
    reportDefinition.sectionSorts = this.generateSectionSort(null, parameter.variant);
    reportDefinition.variant = parameter.variant;

    return reportDefinition;
  }

  private generateSectionSort(sortDefinition: IReportSortDefinition[], variant: ProductSalesReportVariant): IReportSectionSortDefinition[] {

    let sectionSort: IReportSectionSortDefinition = {
      reportSectionName: "Sold Items",
      sorts: []
    }

    switch (variant){
      case ProductSalesReportVariant.Simple:
        sectionSort.sorts.push(this.defaultSimpleSort);
        break;
      case ProductSalesReportVariant.Consolidated:
        sectionSort.sorts.push(this.defaultConsolidatedSort);
        break;
      case ProductSalesReportVariant.Expanded:
        sectionSort.sorts.push(this.defaultExpandedSort);
        break;
      case ProductSalesReportVariant.ExpandedConsolidated:
        sectionSort.sorts.push(this.defaultExpandedConsolidatedSort);
        break;
      default:
        break;
    }
    if (sortDefinition != null){
      sectionSort.sorts.push(sortDefinition[0])
    }

    let sectionSorts: IReportSectionSortDefinition[] = [sectionSort]

    return sectionSorts;
  }

  private urlBuilder(baseUrlMod: string): string {
    const _ProductSalesReportURL = this.ProductSalesReportEndpointTemplate.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId) + baseUrlMod;

    return _ProductSalesReportURL;
  }

  private getFullDate(day: Date, hours: string, minutes: string): Date {

    let fullDate: Date;

    fullDate = day;
    fullDate.setHours( Number(hours), Number(minutes), 0, 0);

    return fullDate;
  }
}
