import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { ReportService } from '../../../services/report.service';

import * as eowReportActions from './eowReport.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { IEOWItem } from 'src/app/models/IEOWItem';
import { IEowReportDocument } from 'src/app/models/IEowReportDocument';
import { HttpErrorResponse } from '@angular/common/http';
import { interpretateApiError } from 'src/app/helpers/InterpretateApiError';

@Injectable()
export class EowReportEffects {
    constructor(private action$: Actions, private eowReportService: ReportService) {

    }

    loadEowItems$ = createEffect(
      () => this.action$.pipe(
        ofType(eowReportActions.EowReportActionTypes.LoadEOW),
        mergeMap((action: eowReportActions.LoadEOW) => {

          let fromDate: Date;
          let toDate: Date;

          fromDate = action.payload.searchFromDate;
          toDate = action.payload.searchToDate;
          toDate.setHours(23,59,59,999);

          // store the parameters into the localstorage
          action.payload.storeParameters();

          return this.eowReportService.getEOWReportsList(fromDate, toDate).pipe(
            map((items: IEOWItem[]) => (new eowReportActions.LoadEOWSuccessful(items)),
            catchError((error: HttpErrorResponse) => of(new eowReportActions.LoadEOWFailed(interpretateApiError(error))))
          ));
        }
      ))
    );

    selectEOW$ =  createEffect(
      () => this.action$.pipe(
        ofType(eowReportActions.EowReportActionTypes.SelectEOW),
        mergeMap((action: eowReportActions.SelectEOW) => {
          return this.eowReportService.getEOWReport(action.payload.eowId).pipe(
            map((eowDocument: IEowReportDocument) => new eowReportActions.SelectedEOW(eowDocument),
            catchError((error: HttpErrorResponse) => of(new eowReportActions.SelectEOWFailed(interpretateApiError(error))))
          ));
        }
      ))
    );

    downloadEodFile$ =  createEffect(
      () => this.action$.pipe(
        ofType(eowReportActions.EowReportActionTypes.DownloadEOW),
        mergeMap((action: eowReportActions.DownloadEOW) =>
            this.eowReportService.getEowReportFile(action.payload).pipe(
                map((file: ArrayBuffer) => (new eowReportActions.DownloadEOWSuccessful(file)),
                catchError((error: HttpErrorResponse) => of(new eowReportActions.DownloadEOWFailed(interpretateApiError(error))))
        )),
      ))
    );
}
