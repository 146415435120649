import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VoidReportActions, VoidReportActionTypes } from './void-report.actions';
import { IReport, Report } from 'src/app/models/Report/Components/IReport';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';
import { VoidReportParameters } from 'src/app/models/Report/ReportParameters/IVoidReportParameters';

export interface State extends fromRoot.State {
  VoidReports: VoidReportState;
}

export interface VoidReportState extends IGardiffStoreSliceBase {
  VoidReportParameters: VoidReportParameters;
  VoidReport: IReport;
  downloadedPDF: ArrayBuffer;
  downloadedXLS: ArrayBuffer;
}

const initialState: VoidReportState = {
  VoidReportParameters: new VoidReportParameters(),
  VoidReport: null,
  downloadedPDF: null,
  downloadedXLS: null,
  apiError: null,
  status: new GardiffStoreSliceStatus()
};

// create the selectors
const getVoidReportFeatureState = createFeatureSelector<VoidReportState>('voidReport');
export const getVoidReportState = createSelector(
    getVoidReportFeatureState,
    state => state
);

export const getParameters = createSelector(
    getVoidReportFeatureState,
    state => state.VoidReportParameters
);

export const getVoidReport = createSelector(
    getVoidReportFeatureState,
    state => state.VoidReport
);

export const getPDFFile = createSelector(
  getVoidReportFeatureState,
  state => state?.downloadedPDF
);

export const getXLSFile = createSelector(
  getVoidReportFeatureState,
  state => state?.downloadedXLS
);

export const getApiError = createSelector(
  getVoidReportFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getVoidReportFeatureState,
  state => state.status
);

export function reducer(state: VoidReportState = initialState, action: VoidReportActions): VoidReportState {
    switch (action.type) {
        case VoidReportActionTypes.LoadVoidReport:
            return {
                ...state,
                VoidReportParameters: action.payload,
                VoidReport: null,
                apiError: null,
                status: null
            };

        case VoidReportActionTypes.LoadVoidReportSuccessful:
            return {
                ...state,
                VoidReport: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case VoidReportActionTypes.LoadVoidReportFailed:
            return {
                ...state,
                VoidReport: null,
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case VoidReportActionTypes.ResetStore:
          initialState.VoidReportParameters.clearParameters()
            return {
                ...state,
                VoidReportParameters : new VoidReportParameters(),
                VoidReport: initialState.VoidReport,
                apiError: initialState.apiError,
                status: initialState.status
            };

        case VoidReportActionTypes.SaveSearchParameters:
            return {
                ...state,
                VoidReportParameters: action.payload
            };

        case VoidReportActionTypes.DownloadPDF:
          return {
              ...state,
              downloadedPDF: null,
          };

        case VoidReportActionTypes.DownloadPDFSuccessful:
            return {
                ...state,
                apiError: null,
                downloadedPDF: action.payload,
            };

        case VoidReportActionTypes.DownloadPDFFailed:
            return {
                ...state,
                apiError: action.payload,
                downloadedPDF: null
            };

        case VoidReportActionTypes.DownloadXLS:
            return {
                ...state,
                downloadedXLS: null,
            };

        case VoidReportActionTypes.DownloadXLSSuccessful:
            return {
                ...state,
                apiError: null,
                downloadedXLS: action.payload,
            };

        case VoidReportActionTypes.DownloadXLSFailed:
            return {
                ...state,
                apiError: action.payload,
                downloadedXLS: null
            };

        case VoidReportActionTypes.CleanUpFileBuffer:
            return {
                ...state,
                downloadedPDF: initialState.downloadedPDF,
                downloadedXLS: initialState.downloadedXLS
            };

        default:
            return state;
    }
}
