import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductSalesReportActions, ProductSalesReportActionTypes } from './product-sales-report.actions';
import { IReport } from 'src/app/models/Report/Components/IReport';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';
import { ProductSalesReportParameters } from 'src/app/models/Report/ReportParameters/IProductSalesReportParameters';

export interface State extends fromRoot.State {
  ProductSalesReports: ProductSalesReportState;
}

export interface ProductSalesReportState extends IGardiffStoreSliceBase {
  ProductSalesReportParameters: ProductSalesReportParameters;
  ProductSalesReport: IReport;
  downloadedPDF: ArrayBuffer;
  downloadedXLS: ArrayBuffer;
}

const initialState: ProductSalesReportState = {
  ProductSalesReportParameters: new ProductSalesReportParameters(),
  ProductSalesReport: null,
  downloadedPDF: null,
  downloadedXLS: null,
  apiError: null,
  status: new GardiffStoreSliceStatus()
};

// create the selectors
const getProductSalesReportFeatureState = createFeatureSelector<ProductSalesReportState>('productSalesReport');
export const getVoidReportState = createSelector(
  getProductSalesReportFeatureState,
  state => state
);

export const getParameters = createSelector(
  getProductSalesReportFeatureState,
  state => state.ProductSalesReportParameters
);

export const getProductSalesReport = createSelector(
  getProductSalesReportFeatureState,
  state => state.ProductSalesReport
);

export const getPDFFile = createSelector(
  getProductSalesReportFeatureState,
  state => state?.downloadedPDF
);

export const getXLSFile = createSelector(
  getProductSalesReportFeatureState,
  state => state?.downloadedXLS
);

export const getApiError = createSelector(
  getProductSalesReportFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getProductSalesReportFeatureState,
  state => state.status
);

export function reducer(state: ProductSalesReportState = initialState, action: ProductSalesReportActions): ProductSalesReportState {
    switch (action.type) {
        case ProductSalesReportActionTypes.LoadProductSalesReport:
            return {
                ...state,
                ProductSalesReportParameters: action.payload,
                ProductSalesReport: null,
                apiError: null,
                status: null
            };

        case ProductSalesReportActionTypes.LoadProductSalesReportSuccessful:
            return {
                ...state,
                ProductSalesReport: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case ProductSalesReportActionTypes.LoadProductSalesReportFailed:
            return {
                ...state,
                ProductSalesReport: null,
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case ProductSalesReportActionTypes.ResetStore:
          initialState.ProductSalesReportParameters.clearParameters()
            return {
                ...state,
                ProductSalesReportParameters : new ProductSalesReportParameters(),
                ProductSalesReport: initialState.ProductSalesReport,
                apiError: initialState.apiError,
                status: initialState.status
            };

        case ProductSalesReportActionTypes.SaveSearchParameters:
            return {
                ...state,
                ProductSalesReportParameters: action.payload
            };

        case ProductSalesReportActionTypes.DownloadPDF:
          return {
              ...state,
              downloadedPDF: null,
          };

        case ProductSalesReportActionTypes.DownloadPDFSuccessful:
            return {
                ...state,
                apiError: null,
                downloadedPDF: action.payload,
            };

        case ProductSalesReportActionTypes.DownloadPDFFailed:
            return {
                ...state,
                apiError: action.payload,
                downloadedPDF: null
            };

        case ProductSalesReportActionTypes.DownloadXLS:
          return {
              ...state,
              downloadedXLS: null,
          };

        case ProductSalesReportActionTypes.DownloadXLSSuccessful:
            return {
                ...state,
                apiError: null,
                downloadedXLS: action.payload,
            };

        case ProductSalesReportActionTypes.DownloadXLSFailed:
            return {
                ...state,
                apiError: action.payload,
                downloadedXLS: null
            };

        case ProductSalesReportActionTypes.CleanUpFileBuffer:
            return {
                ...state,
                downloadedPDF: initialState.downloadedPDF,
                downloadedXLS: initialState.downloadedXLS
            };

        default:
            return state;
    }
}
