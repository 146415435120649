import * as fromRoot from '../app.state';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PaymentReportActions, PaymentReportActionTypes } from './payment-report.actions';
import { IReport } from 'src/app/models/Report/Components/IReport';
import { IGardiffStoreSliceBase } from 'src/app/models/IGardiffStoreSliceBase';
import { GardiffStoreSliceStatus } from 'src/app/models/IGardiffStoreSliceStatus';
import { PaymentReportParameters } from 'src/app/models/Report/ReportParameters/IPaymentReportParameters';

export interface State extends fromRoot.State {
  PaymentReports: PaymentReportState;
}

export interface PaymentReportState extends IGardiffStoreSliceBase {
  PaymentReportParameters: PaymentReportParameters;
  PaymentReport: IReport;
  downloadedPDF: ArrayBuffer;
  downloadedXLS: ArrayBuffer;
}

const initialState: PaymentReportState = {
  PaymentReportParameters: new PaymentReportParameters(),
  PaymentReport: null,
  downloadedPDF: null,
  downloadedXLS: null,
  apiError: null,
  status: new GardiffStoreSliceStatus()
};

// create the selectors
const getPaymentReportFeatureState = createFeatureSelector<PaymentReportState>('paymentReport');
export const getPaymentReportState = createSelector(
    getPaymentReportFeatureState,
    state => state
);

export const getParameters = createSelector(
    getPaymentReportFeatureState,
    state => state.PaymentReportParameters
);

export const getPaymentReport = createSelector(
    getPaymentReportFeatureState,
    state => state.PaymentReport
);

export const getPDFFile = createSelector(
  getPaymentReportFeatureState,
  state => state?.downloadedPDF
);

export const getXLSFile = createSelector(
  getPaymentReportFeatureState,
  state => state?.downloadedXLS
);

export const getApiError = createSelector(
  getPaymentReportFeatureState,
  state => state.apiError
);

export const getStatus = createSelector(
  getPaymentReportFeatureState,
  state => state.status
);

export function reducer(state: PaymentReportState = initialState, action: PaymentReportActions): PaymentReportState {
    switch (action.type) {
        case PaymentReportActionTypes.LoadPaymentReport:
            return {
                ...state,
                PaymentReportParameters: action.payload,
                PaymentReport: null,
                apiError: null,
                status: null
            };

        case PaymentReportActionTypes.LoadPaymentReportSuccessful:
            return {
                ...state,
                PaymentReport: action.payload,
                apiError: null,
                status: new GardiffStoreSliceStatus(true)
            };

        case PaymentReportActionTypes.LoadPaymentReportFailed:
            return {
                ...state,
                PaymentReport: null,
                apiError: action.payload,
                status: new GardiffStoreSliceStatus()
            };

        case PaymentReportActionTypes.ResetStore:
          initialState.PaymentReportParameters.clearParameters()
            return {
                ...state,
                PaymentReportParameters : new PaymentReportParameters(),
                PaymentReport: initialState.PaymentReport,
                apiError: initialState.apiError,
                status: initialState.status
            };

        case PaymentReportActionTypes.SaveSearchParameters:
            return {
                ...state,
                PaymentReportParameters: action.payload
            };

        case PaymentReportActionTypes.DownloadPDF:
          return {
              ...state,
              downloadedPDF: null,
          };

        case PaymentReportActionTypes.DownloadPDFSuccessful:
            return {
                ...state,
                apiError: null,
                downloadedPDF: action.payload,
            };

        case PaymentReportActionTypes.DownloadPDFFailed:
            return {
                ...state,
                apiError: action.payload,
                downloadedPDF: null
            };

        case PaymentReportActionTypes.DownloadXLS:
            return {
                ...state,
                downloadedXLS: null,
            };

        case PaymentReportActionTypes.DownloadXLSSuccessful:
            return {
                ...state,
                apiError: null,
                downloadedXLS: action.payload,
            };

        case PaymentReportActionTypes.DownloadXLSFailed:
            return {
                ...state,
                apiError: action.payload,
                downloadedXLS: null
            };

        case PaymentReportActionTypes.CleanUpFileBuffer:
            return {
                ...state,
                downloadedPDF: initialState.downloadedPDF,
                downloadedXLS: initialState.downloadedXLS
            };

        default:
            return state;
    }
}
