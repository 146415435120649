import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { reducers } from 'src/app/NGRX/reducer';
import { EffectsModule } from '@ngrx/effects';

// OLD EFFECTS
import { LoginEffects } from '../feature-modules/login/state/login.effects';
import { EstateEffects } from '../feature-modules/estate/state/estate.effects';

import { ProductEditorEffects } from '../feature-modules/product-editor/state/productEditor.effects';
import { AddonEditorEffects } from '../feature-modules/addons/state/addons-editor.effects';
import { CategoryEditorEffects } from '../feature-modules/category-editor/state/categories.effects';

// Reports
import { EodReportEffects } from '../feature-modules/eod/state/eodReport.effects';
import { EowReportEffects } from '../feature-modules/eow/state/eowReport.effects';
import { SalesReportEffects } from '../feature-modules/sales-report/state/sales-report.effects';
import { PaymentsReportEffects } from '../feature-modules/payments-report/state/payments-report.effects';
import { DiscountReportEffects } from './discount-report/discount-report.effects';
import { VoidReportEffects } from './void-report/void-report.effects';
import { TransactionReportEffects } from './transaction-report/transaction-report.effects';
import { ProductSalesReportEffects } from './product-sales-report/product-sales-report.effects';
import { PaymentReportEffects } from './payment-report/payment-report.effects';

// Effects for New slices
import { CoursesEffects } from './courses/courses.effects';
import { PricelevelsEffects } from './pricelevels/pricelevels.effects';
import { SalesareaEffects } from './sales-area/salesarea.effects';
import { KitchenVideosEffects } from './kitchen-videos/kitchenvideos.effects';
import { WeightMeasuresEffects } from './weight-measures/weightmeasures.effects';
import { EposSettingsEffects } from './epos-settings/epossettings.effects';
import { CategoriesEffects } from './categories/categories.effects';
import { SchedulePriceLevelsEffects } from './schedule-pricelevels/schedule-pricelevels.effects';
import { FunctionKeysEffects } from './function-keys/function-keys.effects';
import { RolesEffects } from './roles/roles.effects';
import { UsersEffects } from './users/users.effects';
import { PrintersEffects } from './printers/printers.effects';
import { PosSetupEffects } from './pos-setup/possetup.effects';
import { MenusEffects } from './menus/menus.effects';
import { ZReportEffects } from './zreport/zreport.effects';
import { ParentCategoryEditorEffects } from './parent-categories/parent-category.effects';
import { AddonEffects } from './addons/addons.effects';
import { ProductsEffects } from './products/products.effects';
import { TablePlanEffects } from './table-plan/tableplan.effects';
import { MonitorEffects } from './monitor/monitor.effects';

import { extModules } from './DevTools/devtools';
import { DiscountsEffects } from './discounts/discounts.effects';
import { LogonScreenEffects } from './logon-screen/logon.effects';
import { TaxBandsEffects } from './tax-bands/taxbands.effects';
import { AdminEffects } from './admin/admin.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([]),
    extModules,

    EffectsModule.forFeature([
      AdminEffects,

      EstateEffects,
      LoginEffects,
      MonitorEffects,

      EodReportEffects,
      EowReportEffects,
      SalesReportEffects,
      PaymentsReportEffects,
      DiscountReportEffects,
      VoidReportEffects,
      TransactionReportEffects,
      ProductSalesReportEffects,
      PaymentReportEffects,

      ProductEditorEffects,
      ProductsEffects,
      CategoryEditorEffects,
      CategoriesEffects,
      ParentCategoryEditorEffects,
      CoursesEffects,
      PricelevelsEffects,
      SalesareaEffects,
      SchedulePriceLevelsEffects,
      AddonEditorEffects,
      AddonEffects,
      DiscountsEffects,
      TaxBandsEffects,

      MenusEffects,
      TablePlanEffects,
      LogonScreenEffects,

      FunctionKeysEffects,
      RolesEffects,
      UsersEffects,

      EposSettingsEffects,
      PosSetupEffects,
      PrintersEffects,
      KitchenVideosEffects,
      WeightMeasuresEffects,
      ZReportEffects
    ])
  ]
})
export class NgrxStoreModule { }
