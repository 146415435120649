import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, map, Observable, Subscriber, throwError } from 'rxjs';
import { LoginService } from './login.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { IEFTTerminal } from '../models/IEFTTerminal';
import { IEposSetting } from '../models/IEposSetting';
import { IEnvelopeOutDTO } from '../models/DTOs/IEnvelopeOutDTO';
import { IEposSettingDTO } from '../models/DTOs/IEposSettingDTO';
import { ChangeType } from '../enums/ChangeType';
import { EditDataList, IEditDataList } from '../models/DTOs/IEditDataList';

@Injectable({
  providedIn: 'root'
})
export class SetupService {
  private EFTEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/EFT';
  private ZReportSetupEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/ZReportSetup';
  private SiteSetupEndPoint = environment.APIEndpoint +  'frontend/corporation/{corporationId}/site/{siteId}/Settings';

  private mockEFT: IEFTTerminal[] = [
    {
      EFT_Terminal_GUID: 'f02dbbbf-7578-4c0a-85a5-3f6f6cc14c75',
      PaymentGateway: '0',
      Details: ''
    }
  ];

  private settingsFilterList: string[] = [
    'DarkTheme',

    'WrnIfTblNotAttended',
    'OptForLogoffIdle',
    'CashOffDialogueTimeOut',
    'OrderNumberMaxPeriod',

    'AllowDiscountAndPromotion',
    'ApplyLowestPriceLevel',
    'PromptUserOnZeroPriceProduct',

    'DefaultServiceChargePercentage',
    'PaymentScreenButtonEnabled',
    'PaymentShowCashFixedButtons',
    'PaymentShowCashFiftyButton',
    'PaymentKeyQuantity',
    'PaymentAllowGratuity',
    'PaymentAllowServiceCharge',
    'PaymentDrawerOpenCard',
    'PaymentDrawerOpenPdq',    

    'TradingEndHour',
    'TradingEndMinute',

    'PrepTicketRollup',
    'PrepTicketHideOrderNumber',

    'ReceiptRollup',
    'ReceiptIncludeZeroPriced',
    'ReceiptHideCompanyName',
    'ReceiptHidePrintedBy',
    'ReceiptHidePrintedOn',
    'ReceiptHideOpenedBy',
    'ReceiptHideOpenedOn',
    'ReceiptIncludeTax',
    'ReceiptFooterLine1',
    'ReceiptFooterLine2',
    'ReceiptFooterLine3',
    'ReceiptFooterLine4',
    'ReceiptFooterLine5',
    'ReceiptFooterLine6',
    'ReceiptFooterLine7',
    'ReceiptFooterLine8'
  ];

  constructor(private loginService: LoginService, private http: HttpClient) { }

  GetEFT(CorporationID: string, SiteID: string): Observable<IEFTTerminal[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.EFTEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      // return this.http.get<ISalesArea[]>(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token) }).pipe(
      //   map((data: ISalesArea[]) => {
      //     return data;
      //   }), catchError((err: HttpErrorResponse) => {
      //     return Observable.throwError(err.message);
      // }));
      return new Observable<IEFTTerminal[]>((subscriber: Subscriber<IEFTTerminal[]>) => subscriber.next(this.mockEFT) );
    }
  }

  SaveEFT(CorporationID: string, SiteID: string, OldData: IEFTTerminal[], newData: IEFTTerminal[]): Observable<boolean> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.EFTEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const envelope: IEnvelopeOutDTO<IEFTTerminal[]> = {
        oldData: OldData,
        newData: newData
      };

      //EndPoint not ready yet ...
      // return this.http.post(endPoint, envelope, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token) }).pipe(
      //   map(response => {
      //     return true;
      //   }), catchError((err: HttpErrorResponse) => {
      //     return throwError(err.message);
      // }));

      return new Observable<boolean>((subscriber: Subscriber<boolean>) => subscriber.next(true));
    }
  }

  GetZReportSetup(CorporationID: string, SiteID: string): Observable<number[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.ZReportSetupEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http.get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<number[]>) => {

          if (data.ok) {
            return data.body;
          }

        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  SaveZReportSetup(CorporationID: string, SiteID: string, Data: number[]): Observable<number[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.ZReportSetupEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http.post(endPoint, Data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
        map((data: HttpResponse<number[]>) => {

          if (data.ok) {
            return data.body;
          }

        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  GetEPOSSettings(CorporationID: string, SiteID: string): Observable<IEposSetting[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.SiteSetupEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      return this.http
        .get(endPoint, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IEposSettingDTO[]>) => {

            if (data.ok) {
              return this.formatData(data.body);
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
      );
    }
  }

  private formatData(dataList: IEposSettingDTO[]): IEposSetting[] {
    let list: IEposSetting[] = [];

    let filteredList : IEposSettingDTO[] = this.filterData(dataList);

    // convert list
    filteredList.forEach(item => {
      list.push({
        id: item.id,
        name: item.name,
        settingGUID: item.settingGUID,
        value: JSON.parse(item.value),
        changeState: ChangeType.None
      });
    });

    return list;
  }

  private filterData(dataList: IEposSettingDTO[]): IEposSettingDTO[] {
    let filteredList: IEposSettingDTO[] = [];

    dataList.forEach(setting => {
      if (this.settingsFilterList.includes(setting.name)) {
        filteredList.push(setting);
      }
    });

    return filteredList
  }

  SaveEPOSSettings(CorporationID: string, SiteID: string, Data: IEposSetting[]): Observable<IEposSettingDTO[]> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const endPoint: string = this.SiteSetupEndPoint.replace('{corporationId}', CorporationID).replace('{siteId}', SiteID);

      const data: IEditDataList<IEposSettingDTO> = this.SplitItems(Data);

      //console.log('save settings : ' + JSON.stringify(data));

      return this.http
        .post(endPoint, data, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), observe: 'response' }).pipe(
          map((data: HttpResponse<IEposSettingDTO[]>) => {

            if (data.ok) {
              return this.formatData(data.body);
            }

          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        );
    }
  }

  private DeflateItems(list: IEposSetting[]): IEposSettingDTO[] {
    const newData: IEposSettingDTO[] = [];

    if (list != null) {
      list.forEach(item => {
        newData.push({
          id: item.id,
          name: item.name,
          settingGUID: item.settingGUID,
          value: JSON.stringify(item.value)
        });
      });
    }

    return newData;
  }

  private SplitItems(data: IEposSetting[]): IEditDataList<IEposSettingDTO> {
    let newData: IEditDataList<IEposSettingDTO> = new EditDataList<IEposSettingDTO>();

    newData.newItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.New));
    newData.editItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Edited));
    newData.deleteItems = this.DeflateItems(data.filter(i => i.changeState === ChangeType.Deleted));

    return newData;
  }
}
