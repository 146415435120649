export enum ExpandedProductSalesReportField
{
  None,
  Date,
  User,
  Pos,
  TaxBand,
  Category,
  ParentCategory,
  Course,
  PriceLevel,
  DisplayName,
  Quantity,
  Net,
  Tax,
  LineItemTotalPrice
}

export const ExpandedProductSalesReportFieldLabel = new Map<number, string>([
  [ExpandedProductSalesReportField.None, 'None'],
  [ExpandedProductSalesReportField.Date, 'Date Added'],
  [ExpandedProductSalesReportField.User, 'Added By User'],
  [ExpandedProductSalesReportField.Pos, 'Added On Pos'],
  [ExpandedProductSalesReportField.TaxBand, 'Taxband'],
  [ExpandedProductSalesReportField.Category, 'Category'],
  [ExpandedProductSalesReportField.ParentCategory, 'Parent Category'],
  [ExpandedProductSalesReportField.Course, 'Course'],
  [ExpandedProductSalesReportField.PriceLevel, 'Price Level'],
  [ExpandedProductSalesReportField.DisplayName, 'Product'],
  [ExpandedProductSalesReportField.Quantity, 'Quantity'],
  [ExpandedProductSalesReportField.Net, 'Net'],
  [ExpandedProductSalesReportField.Tax, 'Tax'],
  [ExpandedProductSalesReportField.LineItemTotalPrice, 'Gross']
]);
