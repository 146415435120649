export enum ProductSalesReportField
{
  None,
  Date,
  User,
  Pos,
  TaxBand,
  Category,
  ParentCategory,
  Course,
  PriceLevel,
  DisplayName,
  Quantity,
  LineItemTotalPrice
}

export const ProductSalesReportFieldLabel = new Map<number, string>([
  [ProductSalesReportField.None, 'None'],
  [ProductSalesReportField.Date, 'Date Added'],
  [ProductSalesReportField.User, 'User'],
  [ProductSalesReportField.Pos, 'Pos'],
  [ProductSalesReportField.TaxBand, 'Taxband'],
  [ProductSalesReportField.Category, 'Category'],
  [ProductSalesReportField.ParentCategory, 'Parent Category'],
  [ProductSalesReportField.Course, 'Course'],
  [ProductSalesReportField.PriceLevel, 'Price Level'],
  [ProductSalesReportField.DisplayName, 'Product'],
  [ProductSalesReportField.Quantity, 'Quantity'],
  [ProductSalesReportField.LineItemTotalPrice, 'Price']
]);
