import { Injectable } from '@angular/core';
import { RealmAppService } from './realm-app.service';
import { environment } from 'src/environments/environment';
import { Observable, filter, from } from 'rxjs';
import { GTransaction } from '../models/Documents/GTransaction';
import { GInPlayTable } from '../models/Documents/GInPlayTable';
import { GSetting } from '../models/Documents/GSetting';
import { GTransactionFunctionKey } from '../models/Documents/GTransactionFunctionKey';
import { GFunctionKey } from '../models/Documents/GFunctionKey';
import * as RealmTypes from 'realm-web';
import { SlimGTransaction } from '../feature-modules/dashboard-live/models/Slim/RealmModel/SlimGTransaction';
import { UltraSlimGTransaction } from '../feature-modules/dashboard-live/models/Slim/RealmModel/UltraSlimGTransaction';
import { SlimGFunctionKey } from '../feature-modules/dashboard-live/models/Slim/RealmModel/SlimGFunctionKey';
import { SlimGTransactionFunctionKey } from '../feature-modules/dashboard-live/models/Slim/RealmModel/SlimGTransactionFunctionKey';
import { ErrorVoidAggregationResult } from '../feature-modules/dashboard-live/models/Slim/AggregationResultModel/ErrorVoidAggregationResult';

@Injectable({
  providedIn: 'root'
})
export class DashboardLiveService {

  private realmApp: Realm.App

  private errorFunctionKeyName: string = environment.functionKeys.error;
  private voidFunctionKeyName: string = environment.functionKeys.void;

  private errorFunctionKeyGuid: RealmTypes.BSON.UUID;
  private voidFunctionKeyGuid: RealmTypes.BSON.UUID;

  private TransactionCollection: string = 'GTransaction';
  private InPlayTableCollection: string = 'GInPlayTable';
  private SettingCollection: string = 'GSetting';
  private FunctionKeyCollection: string = 'GFunctionKey';
  private TransactionFunctionKeyCollection: string = 'GTransactionFunctionKey';

  constructor(private realmAppService: RealmAppService) {

  }

  private async getCollection<T extends Realm.Services.MongoDB.Document>(collectionName: string) {
    this.realmApp = await this.realmAppService.getAppInstance();

    const mongo = this.realmApp.currentUser.mongoClient(environment.mongoDB.cluster);
    const collection = mongo.db(environment.mongoDB.database).collection<T>(collectionName);
    return collection;
  }

  public async getLast24HoursTransactions(SiteId: string, TradingEndHours: number, TradingEndMinute: number): Promise<SlimGTransaction[]> {
    const collection = await this.getCollection<GTransaction>(this.TransactionCollection);

    const dateTo = new Date();
    dateTo.setHours(TradingEndHours, TradingEndMinute, 0, 0);
    dateTo.setDate(dateTo.getDate() + 1)

    const Now = new Date();
    const TradingEnd = new Date();
    TradingEnd.setHours(TradingEndHours, TradingEndMinute, 0, 0);

    if (Now < TradingEnd && Now.toDateString() === TradingEnd.toDateString()) {
      dateTo.setDate(dateTo.getDate() -1)
    }

    const Yesterday = new Date();
    Yesterday.setDate(Yesterday.getDate()-1);

    return collection.aggregate([
      {
        '$match': {
          'SiteId': SiteId,
          'SalesDate': {
            '$gte': Yesterday,
            '$lte': dateTo
          }
        }
      },
      {
        $project: {
          _id: 1,
          SalesDate: 1,
          Order: {
            OrderDetails: {
              AddOns: {
                Discounts: {
                  Name: 1,
                  Amount: 1
                },
                LineItemTotalPrice: 1,
                OrderDiscountTotal: 1,
                VoidInfo: 1
              },
              CreatedByUserId: 1,
              CreatedDate: 1,
              Discounts: {
                Name: 1,
                Amount: 1
              },
              DisplayName: 1,
              LineItemTotalPrice: 1,
              OrderDiscountTotal: 1,
              Item_GUID: 1,
              VoidInfo: 1,
              Quantity: 1
            },
            OrderNPOs: {
              CreatedDate: 1,
              DisplayName: 1,
              VoidInfo: 1,
              NpoType: 1,
              NpoValue: 1
            },
            OrderPayments: {
              Change: 1,
              TimeReceived: 1,
              AmountReceived: 1,
              PaymentType: 1
            }
          },
          Total: 1
        }
      }
    ]) as Promise<SlimGTransaction[]>
  }

  public async getTransactions(SiteId: string, TradingEndHours: number, TradingEndMinute: number): Promise<UltraSlimGTransaction[]> {
    const collection = await this.getCollection<GTransaction>(this.TransactionCollection);

    const dateTo = new Date();
    dateTo.setHours(TradingEndHours, TradingEndMinute, 0, 0);
    dateTo.setDate(dateTo.getDate() + 1)

    let dateFrom = new Date();
    dateFrom.setHours(TradingEndHours, TradingEndMinute, 0, 0);
    dateFrom.setDate(dateFrom.getDate() - 56)

    const Now = new Date();
    const TradingEnd = new Date();
    TradingEnd.setHours(TradingEndHours, TradingEndMinute, 0, 0);

    if (Now < TradingEnd && Now.toDateString() === TradingEnd.toDateString()) {
      dateTo.setDate(dateTo.getDate() -1)
      dateFrom.setDate(dateFrom.getDate() -1)
    }

    // check if we need to get data for more days
    const previousMonthFrom = this.getPreviousMonthDate(TradingEndHours, TradingEndMinute);
    if (dateFrom > previousMonthFrom) {
      dateFrom = previousMonthFrom
    }

    return collection.aggregate([
      {
        '$match': {
          'SiteId': SiteId,
          'SalesDate': {
            '$gte': dateFrom,
            '$lte': dateTo
          }
        }
      },
      {
        $project: {
          _id: 1,
          SalesDate: 1,
          Order: {
            OrderPayments: {
              Change: 1,
              TimeReceived: 1,
              AmountReceived: 1
            }
          },
          Total: 1
        }
      }
    ]) as Promise<UltraSlimGTransaction[]>
  }

  public async getInPlayTables(SiteId: string): Promise<GInPlayTable[]> {
    const collection = await this.getCollection<GInPlayTable>(this.InPlayTableCollection);

    return collection.aggregate([
      {
        '$match': {
          'SiteId': SiteId
        }
      }
    ]) as Promise<GInPlayTable[]>
  }

  public async getSettings(SiteId: string): Promise<GSetting[]> {
    const collection = await this.getCollection<GSetting>(this.SettingCollection);

    return collection.aggregate([
      {
        '$match': {
          'SiteId': SiteId
        }
      }
    ]) as Promise<GSetting[]>
  }

  public async getErrors_Voids(SiteId: string, TradingEndHours: number, TradingEndMinute: number): Promise<ErrorVoidAggregationResult> {
    const keys: SlimGFunctionKey[] = await this.getError_VoidFunctionKeys(SiteId)

    this.voidFunctionKeyGuid = keys.find(x => x.Name == this.voidFunctionKeyName)?.FunctionKey_GUID;
    this.errorFunctionKeyGuid = keys.find(x => x.Name == this.errorFunctionKeyName)?.FunctionKey_GUID;

    let keyGuids: RealmTypes.BSON.UUID[] = []

    if (this.voidFunctionKeyGuid != null){
      keyGuids.push(this.voidFunctionKeyGuid);
    }
    if (this.errorFunctionKeyGuid != null){
      keyGuids.push(this.errorFunctionKeyGuid);
    }

    const result: ErrorVoidAggregationResult = {
      Errors: [],
      Voids: []
    }

    if (keyGuids.length != 0){
      const transactionKeys: SlimGTransactionFunctionKey[] = await this.getTransactionFunctionKeys(SiteId, keyGuids, TradingEndHours, TradingEndMinute);

      transactionKeys.forEach(transactionKey => {
        const model = {
          CreatedUser: transactionKey.CreatedUser,
          Value: +transactionKey.Value.toString()
        }

        if (this.voidFunctionKeyGuid?.equals(transactionKey.FunctionKey_GUID)) {
          result.Voids.push(model)
        } else {
          result.Errors.push(model)
        }
      });
    } else {
      console.error("No Error and Void GFunctionKeys found")
    }

    return result;
  }

  private async getError_VoidFunctionKeys(SiteId: string): Promise<SlimGFunctionKey[]> {
    const collection = await this.getCollection<GFunctionKey>(this.FunctionKeyCollection);

    let functionKeyNames = [this.voidFunctionKeyName, this.errorFunctionKeyName];

    return collection.aggregate([
      {
        '$match': {
          'SiteId': SiteId,
          'Name': {
            $in: functionKeyNames
          }
        },
      },
      {
        $project: {
          Name: 1,
          FunctionKey_GUID: 1
        }
      }
    ]) as Promise<SlimGFunctionKey[]>
  }

  private async getTransactionFunctionKeys(SiteId: string, FunctionKeyGUIDs: RealmTypes.BSON.UUID[], TradingEndHours: number, TradingEndMinute: number): Promise<SlimGTransactionFunctionKey[]> {
    const collection = await this.getCollection<GTransactionFunctionKey>(this.TransactionFunctionKeyCollection);

    const dateTo = new Date();
    dateTo.setHours(TradingEndHours, TradingEndMinute, 0, 0);
    dateTo.setDate(dateTo.getDate() + 1)

    const dateFrom = new Date();
    dateFrom.setHours(TradingEndHours, TradingEndMinute, 0, 0);

    const Now = new Date();
    const TradingEnd = new Date();
    TradingEnd.setHours(TradingEndHours, TradingEndMinute, 0, 0);

    if (Now < TradingEnd && Now.toDateString() === TradingEnd.toDateString()) {
      dateTo.setDate(dateTo.getDate() -1)
      dateFrom.setDate(dateFrom.getDate() -1)
    }

    return collection.aggregate([
      {
        '$match': {
          'SiteId': SiteId,
          'FunctionKey_GUID': {
            $in: FunctionKeyGUIDs
          },
          'CreatedTime': {
            '$gte': dateFrom,
            '$lte': dateTo
          }
        }
      },
      {
        $project: {
          CreatedUser: 1,
          FunctionKey_GUID: 1,
          Value: 1
        }
      }
    ]) as Promise<SlimGTransactionFunctionKey[]>
  }

  public async observeTransactions(SiteId: string, TradingEndHours: number, TradingEndMinute: number) {
    const collection = await this.getCollection<GTransaction>(this.TransactionCollection);

    let dateFrom = new Date();
    dateFrom.setHours(TradingEndHours, TradingEndMinute,0,0);
    dateFrom.setDate(dateFrom.getDate() - 56)

    const Now = new Date();
    const TradingEnd = new Date();
    TradingEnd.setHours(TradingEndHours, TradingEndMinute, 0, 0);

    if (Now < TradingEnd && Now.toDateString() === TradingEnd.toDateString()) {
      dateFrom.setDate(dateFrom.getDate() -1)
    }

    // check if we need to get data for more days
    const previousMonthFrom = this.getPreviousMonthDate(TradingEndHours, TradingEndMinute);
    if (dateFrom > previousMonthFrom) {
      dateFrom = previousMonthFrom
    }

    const generator = collection.watch({
      filter: {
        'fullDocument.SiteId': SiteId,
        'fullDocument.SalesDate': {
          '$gte': dateFrom
        }
      }
    });

    return fromChangeEvent<GTransaction>(generator);
  }

  public async observeInPlayTables(SiteId: string) {
    const collection = await this.getCollection<GInPlayTable>(this.InPlayTableCollection);

    const generator = collection.watch({
      filter: {
        '$or':[
          {
            'fullDocument.SiteId': SiteId
          },
          {
            'operationType': 'delete'
          }
        ]
      }
    });

    return fromChangeEvent<GInPlayTable>(generator);
  }

  public async observeVoids(SiteId: string, TradingEndHours: number, TradingEndMinute: number) {
    if (this.voidFunctionKeyGuid != null){
      return this.observeTransactionFunctionKeys(SiteId, this.voidFunctionKeyGuid, TradingEndHours, TradingEndMinute);
    }
  }

  public async observeErrors(SiteId: string, TradingEndHours: number, TradingEndMinute: number) {
    if (this.errorFunctionKeyGuid != null){
      return this.observeTransactionFunctionKeys(SiteId, this.errorFunctionKeyGuid, TradingEndHours, TradingEndMinute);
    }
  }

  private async observeTransactionFunctionKeys(SiteId: string, FunctionKeyGUID: RealmTypes.BSON.UUID, TradingEndHours: number, TradingEndMinute: number) {
    const collection = await this.getCollection<GTransactionFunctionKey>(this.TransactionFunctionKeyCollection);

    const dateFrom = new Date();
    dateFrom.setHours(TradingEndHours, TradingEndMinute, 0, 0);

    const Now = new Date();
    const TradingEnd = new Date();
    TradingEnd.setHours(TradingEndHours, TradingEndMinute, 0, 0);

    if (Now < TradingEnd && Now.toDateString() === TradingEnd.toDateString()) {
      dateFrom.setDate(dateFrom.getDate() -1)
    }

    const generator = collection.watch({
      filter: {
        'fullDocument.SiteId': SiteId,
        'fullDocument.FunctionKey_GUID': FunctionKeyGUID,
        'fullDocument.CreatedTime': {
          '$gte': dateFrom
        }
      }
    });

    return fromChangeEvent<GTransactionFunctionKey>(generator).pipe(
      filter(isNewTransactionFunctionKeyEvent)
    );
  }

  private getPreviousMonthDate(TradingEndHours: number, TradingEndMinute: number): Date {
    // check if we need more days
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth();

    let previousMonthFrom = new Date(year, month - 1, 1);
    previousMonthFrom.setHours(TradingEndHours, TradingEndMinute, 0, 0);

    return previousMonthFrom;
  }
}

const isNewTransactionFunctionKeyEvent = (event: any): event is Realm.Services.MongoDB.InsertEvent<GTransactionFunctionKey> =>
  event.operationType === 'insert';

export function fromChangeEvent<T extends Realm.Services.MongoDB.Document>(source: AsyncGenerator<Realm.Services.MongoDB.ChangeEvent<T>>)
  : Observable<Realm.Services.MongoDB.ChangeEvent<T>> {

  return new Observable(subscriber => {
    const subscription = from(source).subscribe({
      next(value) {
        subscriber.next(value);
      },
      error(error) {
        subscriber.error(error);
      },
      complete() {
        subscriber.complete();
      }
    })

    return () => {
      // Stop the collection watcher
      source.return(undefined);

      subscription.unsubscribe();
    };
  });
}
