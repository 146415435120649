import { Action } from '@ngrx/store';
import { IReport } from 'src/app/models/Report/Components/IReport';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { ProductSalesReportParameters } from 'src/app/models/Report/ReportParameters/IProductSalesReportParameters';
import { IReportDefinition } from 'src/app/models/Report/Components/IReportDefinition';
import { IReportPDFDefinition } from 'src/app/models/Report/Components/IReportPDFDefinition';
import { IReportSortDefinition } from 'src/app/models/Report/Components/IReportSortDefinition';
import { IReportXLSDefinition } from 'src/app/models/Report/Components/IReportXLSDefinition';
import { ProductSalesReportVariant } from 'src/app/enums/Report/ReportField/ProductSales/ProductSalesReportVariant';

export enum ProductSalesReportActionTypes {
    LoadProductSalesReport = '[Product Sales Report] Load Product Sales Report',
    LoadProductSalesReportSuccessful = '[Product Sales Report] Load Product Sales Report - Successful',
    LoadProductSalesReportFailed = '[Product Sales Report] Load Product Sales Report - Failed',
    ResetStore = '[Product Sales Report] Reset Store',
    SaveSearchParameters = '[Product Sales Report] Save Search Parameters',
    DownloadPDF = '[Product Sales Reports] Download Product Sales Report PDF',
    DownloadPDFSuccessful = '[Product Sales Reports] Download Product Sales Report PDF - Successful',
    DownloadPDFFailed = '[Product Sales Reports] Download Product Sales Report PDF - Failed',
    DownloadXLS = '[Product Sales Reports] Download Product Sales Report XLS',
    DownloadXLSSuccessful = '[Product Sales Reports] Download Product Sales Report XLS - Successful',
    DownloadXLSFailed = '[Product Sales Reports] Download Product Sales Report XLS - Failed',
    CleanUpFileBuffer = '[Product Sales Reports] Clean up file buffer'
}

export class LoadProductSalesReport implements Action {
    readonly type = ProductSalesReportActionTypes.LoadProductSalesReport;

    constructor(public payload: ProductSalesReportParameters) { }
}

export class LoadProductSalesReportSuccessful implements Action {
    readonly type = ProductSalesReportActionTypes.LoadProductSalesReportSuccessful;

    constructor(public payload: IReport) { }
}

export class LoadProductSalesReportFailed implements Action {
    readonly type = ProductSalesReportActionTypes.LoadProductSalesReportFailed;

    constructor(public payload: IGenericError) { }
}

export class ResetStore implements Action {
    readonly type = ProductSalesReportActionTypes.ResetStore;

    constructor() { }
}

export class SaveSearchParameters implements Action {
    readonly type = ProductSalesReportActionTypes.SaveSearchParameters;

    constructor(public payload: ProductSalesReportParameters) { }
}

export class DownloadPDF implements Action {
  readonly type = ProductSalesReportActionTypes.DownloadPDF;

  constructor(public definition: IReportDefinition, public pdfDefinition: IReportPDFDefinition, public sortDefinition: IReportSortDefinition[]) { }
}

export class DownloadPDFSuccessful implements Action {
  readonly type = ProductSalesReportActionTypes.DownloadPDFSuccessful;

  constructor(public payload: ArrayBuffer) { }
}

export class DownloadPDFFailed implements Action {
  readonly type = ProductSalesReportActionTypes.DownloadPDFFailed;

  constructor(public payload: IGenericError) { }
}

export class DownloadXLS implements Action {
  readonly type = ProductSalesReportActionTypes.DownloadXLS;

  constructor(public definition: IReportDefinition, public xlsDefinition: IReportXLSDefinition, public sortDefinition: IReportSortDefinition[]) { }
}

export class DownloadXLSSuccessful implements Action {
  readonly type = ProductSalesReportActionTypes.DownloadXLSSuccessful;

  constructor(public payload: ArrayBuffer) { }
}

export class DownloadXLSFailed implements Action {
  readonly type = ProductSalesReportActionTypes.DownloadXLSFailed;

  constructor(public payload: IGenericError) { }
}

export class CleanUpFileBuffer implements Action {
  readonly type = ProductSalesReportActionTypes.CleanUpFileBuffer;

  constructor() { }
}

export type ProductSalesReportActions = LoadProductSalesReport
    | LoadProductSalesReportSuccessful
    | LoadProductSalesReportFailed
    | ResetStore
    | SaveSearchParameters
    | DownloadPDF
    | DownloadPDFSuccessful
    | DownloadPDFFailed
    | DownloadXLS
    | DownloadXLSSuccessful
    | DownloadXLSFailed
    | CleanUpFileBuffer;
