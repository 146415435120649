export enum PaymentReportField
{
  None,
  Date,
  User,
  Pos,
  Description,
  Quantity,
  Amount
}

export const PaymentReportFieldLabel = new Map<number, string>([
  [PaymentReportField.None, 'None'],
  [PaymentReportField.Date, 'Date Received'],
  [PaymentReportField.User, 'User'],
  [PaymentReportField.Pos, 'Pos'],
  [PaymentReportField.Description, 'Payment Type'],
  [PaymentReportField.Quantity, 'Quantity'],
  [PaymentReportField.Amount, 'Amount']
]);
