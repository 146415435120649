import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IReport, Report } from '../../../models/Report/Components/IReport';
import { EstateService } from '../../estate.service';
import { LoginService } from '../../login.service';
import { PaymentReportField } from '../../../enums/Report/ReportField/PaymentReportField';
import { IReportDefinition, ReportDefinition } from '../../../models/Report/Components/IReportDefinition';
import { OperatorType } from '../../../enums/Report/Components/OperatorType';
import { PaymentReportParameters } from '../../../models/Report/ReportParameters/IPaymentReportParameters';
import { IReportPDFDefinition, ReportPDFDefinition } from '../../../models/Report/Components/IReportPDFDefinition';
import { ReportPDFEnvelope } from '../../../models/Report/Components/IReportPDFEnvelope';
import { IReportSortDefinition } from '../../../models/Report/Components/IReportSortDefinition';
import { IReportSectionSortDefinition } from 'src/app/models/Report/Components/IReportSectionSortDefinition';
import { ReportXLSEnvelope } from 'src/app/models/Report/Components/IReportXLSEnvelope';
import { IReportXLSDefinition } from 'src/app/models/Report/Components/IReportXLSDefinition';

@Injectable({
  providedIn: 'root'
})
export class PaymentReportService {

  private defaultSort: IReportSortDefinition = {
    fieldNumber: PaymentReportField.Date - 1,
    isAscending: false
  };

  private token: string;
  private PaymentReportEndpointTemplate = environment.APIEndpoint + 'frontend/corporation/{corporationId}/site/{siteId}/PaymentReport/';

  constructor(private http: HttpClient, private loginService: LoginService, private estateService: EstateService) { }

  getPaymentReport(parameter: PaymentReportParameters): Observable<IReport> {
    this.token = this.loginService.AccessToken.authToken;

    let definition: IReportDefinition = this.generatePaymentReportDefinition(parameter);

    const _PaymentReportURL = this.PaymentReportEndpointTemplate.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId);

    return this.http.post<IReport>(_PaymentReportURL, definition, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token) }).pipe(
      map((data: IReport) => {

        if (data == null) {
          data = new Report();
        }

        data.reportDefinition = definition
        data.reportPDFDefinition = new ReportPDFDefinition()

        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(() => err);
      })
    );
  }

  // Returns Payment Report PDF
  getReportFilePDF(definition: IReportDefinition, pdfDefinition: IReportPDFDefinition, sortDefinition: IReportSortDefinition[]): Observable<ArrayBuffer> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const _PaymentReportPDFURL = this.PaymentReportEndpointTemplate.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId)  + 'PDF';

      definition.sectionSorts = this.generateSectionSort(sortDefinition);

      let envelope: ReportPDFEnvelope = {
        reportDefinition: definition,
        reportPDFDefinition: pdfDefinition
      }

      return this.http.post(_PaymentReportPDFURL, envelope, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), responseType: 'arraybuffer'}).pipe(
        map((data: ArrayBuffer) => {

          return data;

        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  // Returns Payment Report XLS
  getReportFileXLS(definition: IReportDefinition, xlsDefinition: IReportXLSDefinition, sortDefinition: IReportSortDefinition[]): Observable<ArrayBuffer> {
    const Token: string = this.loginService.AccessToken.authToken;
    if (Token !== '') {
      const _PaymentReportPDFURL = this.PaymentReportEndpointTemplate.replace('{corporationId}', this.estateService.CorporationID).replace('{siteId}', this.estateService.SelectedSite.siteId)  + 'XLS';

      definition.sectionSorts = this.generateSectionSort(sortDefinition);

      let envelope: ReportXLSEnvelope = {
        reportDefinition: definition,
        reportXLSDefinition: xlsDefinition
      }

      return this.http.post(_PaymentReportPDFURL, envelope, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + Token), responseType: 'arraybuffer'}).pipe(
        map((data: ArrayBuffer) => {

          return data;

        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      );
    }
  }

  private generatePaymentReportDefinition(parameter: PaymentReportParameters): IReportDefinition {
    let reportDefinition = new ReportDefinition();

    reportDefinition.groupedFieldEnumValue = [parameter.reportGroup];

    let paymentedDateFrom = this.getFullDate(parameter.dateFromDay, parameter.dateFromHour, parameter.dateFromMinute)

    reportDefinition.filters.push({
      fieldEnumValue: PaymentReportField.Date,
      operatorType: OperatorType.GreaterThanOrEqual,
      value: paymentedDateFrom
    });

    let paymentedDateTo = this.getFullDate(parameter.dateToDay, parameter.dateToHour, parameter.dateToMinute)

    reportDefinition.filters.push({
      fieldEnumValue: PaymentReportField.Date,
      operatorType: OperatorType.LessThan,
      value: paymentedDateTo
    });

    if (parameter.createdPosId != -1){
      reportDefinition.filters.push({
        fieldEnumValue: PaymentReportField.Pos,
        operatorType: OperatorType.Equal,
        value: parameter.createdPosId
      });
    }

    if (parameter.createdClerkId != -1){
      reportDefinition.filters.push({
        fieldEnumValue: PaymentReportField.User,
        operatorType: OperatorType.Equal,
        value: parameter.createdClerkId
      });
    }

    //default sort
    reportDefinition.sectionSorts = this.generateSectionSort(null)

    return reportDefinition;
  }

  private generateSectionSort(sortDefinition: IReportSortDefinition[]): IReportSectionSortDefinition[] {
    let sectionSorts = [{
      reportSectionName: "Paymented Items",
      sorts: [this.defaultSort]
    }]

    if (sortDefinition != null){
      sectionSorts[0].sorts.push(sortDefinition[0])
    }

    return sectionSorts;
  }

  private getFullDate(day: Date, hours: string, minutes: string): Date {

    let fullDate: Date;

    fullDate = day;
    fullDate.setHours( Number(hours), Number(minutes), 0, 0);

    return fullDate;
  }
}
