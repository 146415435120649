import { IParentCategorySlimDTO } from "./DTOs/IParentCategorySlimDTO";

export interface IParentCategorySlim extends IParentCategorySlimDTO {
}

export class ParentCategorySlim implements IParentCategorySlim {
    id: string;
    parentCategoryGuid: string;
    description: string;

    constructor() {
        this.parentCategoryGuid = '';
        this.description = '';
    }
}
