import { Action } from '@ngrx/store';
import { IReport } from 'src/app/models/Report/Components/IReport';
import { IGenericError } from 'src/app/models/apiErrorDTOs/IGenericError';
import { VoidReportParameters } from 'src/app/models/Report/ReportParameters/IVoidReportParameters';
import { IReportDefinition } from 'src/app/models/Report/Components/IReportDefinition';
import { IReportPDFDefinition } from 'src/app/models/Report/Components/IReportPDFDefinition';
import { IReportSortDefinition } from 'src/app/models/Report/Components/IReportSortDefinition';
import { IReportXLSDefinition } from 'src/app/models/Report/Components/IReportXLSDefinition';

export enum VoidReportActionTypes {
    LoadVoidReport = '[Void Report] Load Void Report',
    LoadVoidReportSuccessful = '[Void Report] Load Void Report - Successful',
    LoadVoidReportFailed = '[Void Report] Load Void Report - Failed',
    ResetStore = '[Void Report] Reset Store',
    SaveSearchParameters = '[Void Report] Save Search Parameters',
    DownloadPDF = '[Void Reports] Download Void Reports PDF',
    DownloadPDFSuccessful = '[Void Reports] Download Void Reports PDF - Successful',
    DownloadPDFFailed = '[Void Reports] Download Void Reports PDF - Failed',
    DownloadXLS = '[Void Reports] Download Void Reports XLS',
    DownloadXLSSuccessful = '[Void Reports] Download Void Reports XLS - Successful',
    DownloadXLSFailed = '[Void Reports] Download Void Reports XLS - Failed',
    CleanUpFileBuffer = '[Void Reports] Clean up file buffer'
}

export class LoadVoidReport implements Action {
    readonly type = VoidReportActionTypes.LoadVoidReport;

    constructor(public payload: VoidReportParameters) { }
}

export class LoadVoidReportSuccessful implements Action {
    readonly type = VoidReportActionTypes.LoadVoidReportSuccessful;

    constructor(public payload: IReport) { }
}

export class LoadVoidReportFailed implements Action {
    readonly type = VoidReportActionTypes.LoadVoidReportFailed;

    constructor(public payload: IGenericError) { }
}

export class ResetStore implements Action {
    readonly type = VoidReportActionTypes.ResetStore;

    constructor() { }
}

export class SaveSearchParameters implements Action {
    readonly type = VoidReportActionTypes.SaveSearchParameters;

    constructor(public payload: VoidReportParameters) { }
}

export class DownloadPDF implements Action {
  readonly type = VoidReportActionTypes.DownloadPDF;

  constructor(public definition: IReportDefinition, public pdfDefinition: IReportPDFDefinition, public sortDefinition: IReportSortDefinition[]) { }
}

export class DownloadPDFSuccessful implements Action {
  readonly type = VoidReportActionTypes.DownloadPDFSuccessful;

  constructor(public payload: ArrayBuffer) { }
}

export class DownloadPDFFailed implements Action {
  readonly type = VoidReportActionTypes.DownloadPDFFailed;

  constructor(public payload: IGenericError) { }
}

export class DownloadXLS implements Action {
  readonly type = VoidReportActionTypes.DownloadXLS;

  constructor(public definition: IReportDefinition, public xlsDefinition: IReportXLSDefinition, public sortDefinition: IReportSortDefinition[]) { }
}

export class DownloadXLSSuccessful implements Action {
  readonly type = VoidReportActionTypes.DownloadXLSSuccessful;

  constructor(public payload: ArrayBuffer) { }
}

export class DownloadXLSFailed implements Action {
  readonly type = VoidReportActionTypes.DownloadXLSFailed;

  constructor(public payload: IGenericError) { }
}

export class CleanUpFileBuffer implements Action {
  readonly type = VoidReportActionTypes.CleanUpFileBuffer;

  constructor() { }
}

export type VoidReportActions = LoadVoidReport
    | LoadVoidReportSuccessful
    | LoadVoidReportFailed
    | ResetStore
    | SaveSearchParameters
    | DownloadPDF
    | DownloadPDFSuccessful
    | DownloadPDFFailed
    | DownloadXLS
    | DownloadXLSSuccessful
    | DownloadXLSFailed
    | CleanUpFileBuffer;
