import { PaymentReportField } from "src/app/enums/Report/ReportField/PaymentReportField";
import { IReportSortDefinition } from "../Components/IReportSortDefinition";

const localStorageFormParametersProperty = 'PaymentReportParameters';

export class PaymentReportParameters {
  reportGroup: PaymentReportField;
  createdPosId: number;
  createdClerkId: number;
  dateFromDay: Date;
  dateFromHour: string;
  dateFromMinute: string;
  dateToDay: Date;
  dateToHour: string;
  dateToMinute: string;
  sort: IReportSortDefinition[];

  constructor() {
      this.getParameters();
  }

  public storeParameters() {
      localStorage.setItem(localStorageFormParametersProperty, JSON.stringify(this));
  }

  public clearParameters() {
      localStorage.removeItem(localStorageFormParametersProperty);
  }

  private getParameters() {
    const storedParameters: PaymentReportParameters = JSON.parse(localStorage.getItem(localStorageFormParametersProperty));

    if (storedParameters != null) {
      // we found the parameters into the local storage
      this.reportGroup = <PaymentReportField>storedParameters.reportGroup;
      this.createdPosId = storedParameters.createdPosId;
      this.createdClerkId = storedParameters.createdClerkId;
      this.dateFromDay = new Date(storedParameters.dateFromDay);
      this.dateFromHour = storedParameters.dateFromHour;
      this.dateFromMinute = storedParameters.dateFromMinute;
      this.dateToDay = new Date(storedParameters.dateToDay);
      this.dateToHour = storedParameters.dateToHour;
      this.dateToMinute = storedParameters.dateToMinute;

    } else {
      // we need to generate default value
      this.reportGroup = PaymentReportField.None;
      this.createdPosId = -1;
      this.createdClerkId = -1;
      this.dateFromDay = new Date(new Date().setDate(new Date().getDate() - 7));
      this.dateFromHour = '00';
      this.dateFromMinute = '00';
      this.dateToDay = new Date();
      this.dateToHour = '23';
      this.dateToMinute = '59';

      this.storeParameters();
    }
  }
}
