import { IReportFilterDefinition } from "./IReportFilterDefinition";
import { IReportSectionSortDefinition } from "./IReportSectionSortDefinition";

export interface IReportDefinition {
  variant: number;
  filters: IReportFilterDefinition[];
  groupedFieldEnumValue: number[];
  sectionSorts: IReportSectionSortDefinition[];
  timeZone: string;
}

export class ReportDefinition implements IReportDefinition {
  variant: number;
  filters: IReportFilterDefinition[];
  groupedFieldEnumValue: number[];
  sectionSorts: IReportSectionSortDefinition[];
  timeZone: string;

  constructor(){
    this.variant = 0;
    this.filters = [];
    this.groupedFieldEnumValue = [0];
    this.sectionSorts = [];
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
